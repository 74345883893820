import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import axiosInstance from 'src/utils/axios'

function PoolBonus() {
    const {enqueueSnackbar} = useSnackbar()
    const [isEnable, setIsEnable] = useState(0)
    const [loading,setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
            const { data } = (await axiosInstance.post("api/pool-commission")).data;
            setIsEnable(data.is_active_bonus)
            } catch (error) {
                enqueueSnackbar(error.message, { variant: "error" })
            }
        }
        fetchData()
    },[])

    const onSubmit = async () => {
        try {
            const reqData = new FormData();
            reqData.append("type", "bonus")

            setLoading(true)
            const { data } = await axiosInstance.post("api/pool-commission",reqData);
            setIsEnable(data.data.is_active_bonus)
            setLoading(false)
            enqueueSnackbar(data.message, { variant: "success" })
        } catch (error) {
            setLoading(false)
            enqueueSnackbar(error.message, { variant: "error" })
        }
    }
  return (
   <LoadingButton disabled={!isEnable} loading={loading} onClick={onSubmit}  variant="contained">Pool Bonus</LoadingButton >
  )
}

export default PoolBonus
