import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const Salary = Loadable(lazy(() => import("src/pages/admin/salary/salary")));
const History = Loadable(lazy(() => import("src/pages/admin/salary/history")));

const salary = [
  {
    path: "salary",
    children: [
      { element: <Navigate to="salary" />, index: true },
      { path: "salary", element: <Salary /> },
      { path: "history/:id", element: <History /> },
    ],
  },
];

export default salary;
